document.addEventListener("turbolinks:before-cache", function() {
  if ($('.js-source-select2').first().data('select2') != undefined) $('.js-source-select2').select2('destroy');
  if ($('.calender-select2').first().data('select2') != undefined) $('.calender-select2').select2('destroy');
  if ($('.js-source-select2-h-search').first().data('select2') != undefined) $('.js-source-select2-h-search').select2('destroy');
  if ($('.js-source-select2-multiple').first().data('select2') != undefined) $('.js-source-select2-multiple').select2('destroy');
});

document.addEventListener("turbolinks:load", function() {
  $('.select2-container').remove() //remove the select2 container DOM
  $('.select2-input').select2(); // Select2 will just reinit the DOM

  $(".js-source-select2").select2();

  $(".calender-select2").select2({
    minimumResultsForSearch: -1,
    dropdownCssClass : 'dropdown-calendar'
  }); 

  $(".phone-select2").select2({
    minimumResultsForSearch: -1,
    dropdownCssClass : 'dropdown-phone'
  }); 

  $(".js-source-select2-h-search").select2({
    minimumResultsForSearch: -1  
  });

  $(".js-source-select2-multiple").select2({
    tags: true,
    tokenSeparators: [',', ' ', ';', '\n'],
    createTag: function (params) {
      return {
        id: params.term,
        text: params.term
      }
    }
  })

  $(".js-source-select2-eq-items").select2({})

  $('.js-source-select2-eq-items').on('select2:open', function (e) {
    $('#select2-equipment_group_equipment_item_ids-results.select2-results__options').css('display','none')
    $(".select2-dropdown.select2-dropdown--below").css('display','none')
    $("#openEquipmentSearch").click()
  });

  $(".js-source-select2-companies").select2({})

  $('.js-source-select2-companies').on('select2:open', function (e) {
    $('#select2-equipment_group_company_ids-results.select2-results__options').css('display','none')
    $(".select2-dropdown.select2-dropdown--below").css('display','none')
    $("#openCompanySearch").click()
  });

});

window.convertObjectToSelectOptions = function(obj) {
  var htmlTags = '';
  for (var tag in obj) {
    htmlTags += '<option value="' + obj[tag] + '" selected="selected">' + obj[tag] + '</option>';
  }
  return htmlTags;
}
