window.addEventListener('DOMContentLoaded', () => {
	window.showHidePass = function(e, page) {
		if ( page == 'profile' || page == 'cmsprofile' ) {
			input_pass = $(e).parent().find("input.password")
		} else {
			input_pass = $(e).closest(".form-group").find("input.password")
		}
		type_input = input_pass.attr('type')
		parent 		 = input_pass.closest('.position-relative')
		
		if ( type_input == "password" ) {
			input_pass.attr('type', 'text')
			if ( page == 'profile' ) {
				parent.find('.eye-position-profile').addClass('d-none')
				parent.find('.hide-position-profile').removeClass('d-none')
			} else if ( page == 'sign_in' ) {
				parent_devise = input_pass.closest('.input-group.input-group-merge')
				parent_devise.find('.eye-position').addClass('d-none')
				parent_devise.find('.hide-position').removeClass('d-none')
			} else if (page == 'sign_in_cms' ) {
				parent_devise = input_pass.closest('.input-group.input-group-merge')
				parent_devise.find('.eye-position-cms').addClass('d-none')
				parent_devise.find('.hide-position-cms').removeClass('d-none')
			} else if ( page == 'cmsprofile' ) {
				parent.find('.eye-position-cmsprofile').addClass('d-none')
				parent.find('.hide-position-cmsprofile').removeClass('d-none')
			} else {
				parent.find('.eye-position').addClass('d-none')
				parent.find('.hide-position').removeClass('d-none')
			}
			
		} else {
			input_pass.attr('type', 'password')
			if ( page == 'profile' ) {
				parent.find('.hide-position-profile').addClass('d-none')
				parent.find('.eye-position-profile').removeClass('d-none')
			} else if ( page == 'sign_in' ) {
				parent_devise = input_pass.closest('.input-group.input-group-merge')
				parent_devise.find('.hide-position').addClass('d-none')
				parent_devise.find('.eye-position').removeClass('d-none')
			} else if ( page == 'sign_in_cms' ) {
				parent_devise = input_pass.closest('.input-group.input-group-merge')
				parent_devise.find('.hide-position-cms').addClass('d-none')
				parent_devise.find('.eye-position-cms').removeClass('d-none')
			} else if ( page == 'cmsprofile' ) {
				parent.find('.hide-position-cmsprofile').addClass('d-none')
				parent.find('.eye-position-cmsprofile').removeClass('d-none')
			} else {
				parent.find('.hide-position').addClass('d-none')
				parent.find('.eye-position').removeClass('d-none')
			}
		}
	}
});
