window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery'
require("bootstrap")
require("@popperjs/core")
// require("jquery-clock-timepicker")
require("flatpickr")
import flatpickr from "flatpickr"
global.weekSelect = require("flatpickr/dist/plugins/weekSelect/weekSelect");
global.monthSelectPlugin = require("flatpickr/dist/plugins/monthSelect/index");
require("flatpickr/dist/flatpickr.css")
require("flatpickr/dist/plugins/monthSelect/style.css")
require("@nathanvda/cocoon")

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import "./plugin/select2.js"
import "./plugin/validation.js"
import "./plugin/jquery.minicolors.js"
import "./plugin/spinned.js"
import "./plugin/toggle-password.js"
import "./plugin/timepicker.js"

import './js/admin/equipment.js'
import './js/admin/menu-toggle.js'
import './js/admin/minicolors.js'

import './js/pages/booking.js'
import './js/pages/menu-mobile.js'
import './js/pages/favorites.js'
import './js/pages/dashboard.js'
import './js/pages/confirm-submit.js'
import './js/pages/equipment-groups.js'

require('datatables.net-bs')(window, $)
require('datatables.net-buttons-bs')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
	flatpickr("[data-behavior='flatpickr']", {
		altInput: true,
		altFormat: "F j, Y",
		dateFormat: "Y-m-d",
		altInputClass: "form-control input flat",
		disableMobile: "true",
	}),
  setTimeout(function() {
    $('.success, .alert').fadeOut();
  }, 10000); 
})
