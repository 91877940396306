window.addEventListener('DOMContentLoaded', () => {
	window.toggleLike = function(e, controller) {
		var likeable_id = $(e).data("id")
		var likeable_type = $(e).data("type")
		$.ajax({
			url: "/toggle_like",
			method: 'GET',
			data: {
				likeable_id: likeable_id,
				likeable_type: likeable_type
			},
			success: function (data) {
				if (data.message == "liked") {
					$("#body_client").find(`.likeable${likeable_type}-${likeable_id}`).each(function() {
						console.log($(this))
						$(this).removeClass("icon-favorite-love")
						$(this).addClass("icon-favorite-loved")
					})
				} else if (data.message == "unliked") {
					$("#body_client").find(`.likeable${likeable_type}-${likeable_id}`).each(function() {
						console.log($(this))
						$(this).removeClass("icon-favorite-loved")
						$(this).addClass("icon-favorite-love")
					})
				}
				if (controller == "dashboard") {
					var container = document.getElementById('refreshFavorites')
					var event = new Event('refresh')
					container.dispatchEvent(event)
				}
			}
		})
	}
	
  window.cb_change_favorite = function(cthis) {
  	var favorites = $(cthis).val().split("-")
    $("#booking_orderable_type").val(favorites[0])
    $("#booking_orderable_id").val(favorites[1])
  }
});
