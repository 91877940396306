window.addEventListener('DOMContentLoaded', () => {
	window.filterEquipmentBookings = function() {
    $("#filterBookings").find("#submitQuery").click();
	}

  window.filterModeEquipmentBookings = function() {
    $("#filterBookings").find("#submitQuery").click();
  }

	window.filterMyBookings = function() {
    $("#filterMyBookings").find("#submitQuery").click();
	}

  window.showHiddenUserForm = function(cthis, id) {
    var txt = $(cthis).val()
    var $select = $("body").find(id)[0]
    if (txt == "users") {
      $($select).removeClass("d-none")
    } else if (txt == "maintenance") {
      $($select).addClass("d-none")
    }
  }

	window.onmove_calculation_timer = function(target, min, max, step, calendar_mode, start_date) {
    var slidertitle = $(target).closest("#group_slider").find(".title-slider")[0];
    var sliderbtn = $(target).closest("#group_slider").find(".button-booking")[0];

    var sliderOffsetX = target.getBoundingClientRect().left - document.documentElement.getBoundingClientRect().left;
    var sliderOffsetY = target.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top;

    var sliderWidth = target.offsetWidth - 1;

    var currentMouseXPos = (event.clientX + window.pageXOffset) - sliderOffsetX;
    var sliderValAtPos = Math.round(currentMouseXPos / sliderWidth * max + 1);
    if(sliderValAtPos < min) sliderValAtPos = min;
    if(sliderValAtPos > max) sliderValAtPos = max;

    var arr_timer = render_timer(sliderValAtPos, step, calendar_mode, start_date)

    var currentTitleId = slidertitle.id
    $(".title-slider").not(`#${currentTitleId}`).addClass("d-none")
    if ($(slidertitle).hasClass('d-none')) {
      $(slidertitle).removeClass('d-none')
    }
    // slidertitle.innerHTML = render_tooltips(arr_timer, "only_hour");
    slidertitle.style.top = '-30px';
    slidertitle.style.left = currentMouseXPos - 25 + 'px';

    var currentBtnId = sliderbtn.id
    $(".button-booking").not(`#${currentBtnId}`).addClass("d-none")
    if ($(sliderbtn).hasClass('d-none')) {
      $(sliderbtn).removeClass('d-none')
    }
    sliderbtn.style.top = '0px';
    sliderbtn.style.left = currentMouseXPos - 6 + 'px';
    sliderbtn.dataset.year = arr_timer[0]
    sliderbtn.dataset.month = arr_timer[1]
    sliderbtn.dataset.date = arr_timer[2]
    sliderbtn.dataset.at_time = arr_timer[3]
    sliderbtn.dataset.at_timer = arr_timer[4]
    sliderbtn.dataset.step = step
    sliderbtn.dataset.calendar_mode = calendar_mode
  }

  window.onmove_detail_item = function(target) {
    var slidertitle = $(target).closest("#group_slider").find(".title-slider")[0];
    var sliderbtn = $(target).closest("#group_slider").find(".button-booking")[0];
    $(slidertitle).addClass('d-none')
    $(sliderbtn).addClass('d-none')
  }

  window.render_timer = function(x, step, calendar_mode, start_date) {
    var dt = new Date(start_date)
    switch (calendar_mode) {
      case "hours":
        var date = dt.getDate();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var total_minutes = (x * step)
        var hour = parseInt(total_minutes / 60)
        var mins = total_minutes % 60
        if (mins > 45) {
          var at_min = "45"
        } else {
          if ((mins % 15) > 0) {
            var at_min = (15*(parseInt(mins/15)) + 15).toString()
          } else {
            var at_min = (15*(parseInt(mins/15))).toString()
          }
          
        }
        var at_hout = (hour - (24 * parseInt(hour / 24))).toString()
        return [year, month, date, parseFloat(`${at_hout}.${at_min}`), `${at_hout}:${left_justify(at_min)}`]
        break
      case "dates":
        var total_minutes = x * step
        var hour = parseInt(total_minutes / 60)
        dt.setDate(dt.getDate() + parseInt(hour/24))
        var date = dt.getDate();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var mins = total_minutes % 60
        if (mins > 45) {
          var at_min = "45"
        } else {
          if ((mins % 15) > 0) {
            var at_min = (15*(parseInt(mins/15)) + 15).toString()
          } else {
            var at_min = (15*(parseInt(mins/15))).toString()
          }
          
        }
        var at_hout = (hour - (24 * parseInt(hour / 24))).toString()
        return [year, month, date, parseFloat(`${at_hout}.${at_min}`), `${at_hout}:${left_justify(at_min)}`]
        break
    }
  }

  window.render_tooltips = function(arr_timer, mode) {
    switch (mode) {
      case "only_hour":
        return `<div class='width-60px height-24px text-center'><p>${arr_timer[4]}</p></div>`
        break;
      case "full":
        break;
    }
  }

  window.left_justify = function(char) {
    if (char.length < 2) {
      return "0" + char
    } else {
      return char
    }
  }

  window.popup_modal = function(target, className) {
    disableDescriptionPage()
    var id = target.dataset.id
    var year = target.dataset.year
    var month = target.dataset.month
    var date = target.dataset.date
    var at_time = target.dataset.at_time
    var at_timer = target.dataset.at_timer
    var step = target.dataset.step
    var calendar_mode = target.dataset.calendar_mode
    var orderable_type = target.dataset.orderable_type
    if (className == "User") {
      ajax_new_booking(orderable_type, year, month, date, at_timer, id) 
    } else {
      ajax_new_booking_by_admin(orderable_type, year, month, date, at_timer, id) 
    }
  }

  window.edit_modal = function(target, className) {
    if (className == "User") {
      disableDescriptionPage()
      ajax_edit_booking(target.dataset.id)
    } else {
      disableDescriptionPage()
      ajax_edit_booking_by_admin(target.dataset.id)
    }
    
  }

  window.ajax_new_booking = function(orderable_type, year, month, date, at_timer, id) {
    $.ajax({
      url: "/bookings/new",
      data: {
        orderable_id: id,
        orderable_type: orderable_type,
        start_date: `${year}-${month}-${date}`,
        end_date: `${year}-${month}-${date}`,
        str_start_time: at_timer,
        str_end_time: at_timer
      },
      method: 'GET',
      success: function () {
        enableDescriptionPage()
        $(".title-form-booking").text("Create Booking")
        $("#bookingFormItem").modal("show")
      }
    })
  }

  window.ajax_new_booking_by_admin = function(orderable_type, year, month, date, at_timer, id) {
    $.ajax({
      url: "/admin/bookings/new",
      data: {
        orderable_id: id,
        orderable_type: orderable_type,
        start_date: `${year}-${month}-${date}`,
        end_date: `${year}-${month}-${date}`,
        str_start_time: at_timer,
        str_end_time: at_timer
      },
      method: 'GET',
      success: function () {
        enableDescriptionPage()
        $(".title-form-booking").text("Create Booking")
        $("#bookingFormItem").modal("show")
      }
    })
  }

  window.ajax_edit_booking = function(id) {
    $.ajax({
      url: `/bookings/${id}/edit`,
      method: 'GET',
      success: function () {
        enableDescriptionPage()
        $(".title-form-booking").text("Edit Booking")
        $("#bookingFormItem").modal("show")
      }
    })
  }

  window.ajax_edit_booking_by_admin = function(id) {
    $.ajax({
      url: `/admin/bookings/${id}/edit`,
      method: 'GET',
      success: function () {
        enableDescriptionPage()
        $(".title-form-booking").text("Edit Booking")
        $("#bookingFormItem").modal("show")
      }
    })
  }

  window.btn_new_booking = function(orderable_type, id, screen) {
  	$.ajax({
      url: "/bookings/new",
      data: {
        orderable_id: id,
        orderable_type: orderable_type,
        screen: screen
      },
      method: 'GET',
      success: function () {
        $(".title-form-booking").text("Create Booking")
        $("#bookingFormItem").modal("show")
      }
    })
  }

  window.next_day_filter = function() {
    var str_date = $("#filterStartDate").val()
    var date = new Date(str_date);
    var increase_start_date = new Date(date.setDate(date.getDate() + 1))
    var start_date = increase_start_date.getDate();
    var start_month = increase_start_date.getMonth() + 1;
    var start_year = increase_start_date.getFullYear();
    var start_day = increase_start_date.getDay();
    var fpStart = flatpickr("#filterStartDate", {});
    fpStart.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
    $("#txtDatePicker").text(`${convertDayStr(start_day)}, ${left_justify(start_date.toString())} ${convertMonthStr(start_month)} ${start_year}`)
    filterEquipmentBookings()
  }

  window.previous_day_filter = function() {
    var str_date = $("#filterStartDate").val()
    var date = new Date(str_date);
    var decrease_date = new Date(date.setDate(date.getDate() - 1))
    var date = decrease_date.getDate();
    var month = decrease_date.getMonth() + 1;
    var year = decrease_date.getFullYear();
    var day = decrease_date.getDay();
    var fp = flatpickr("#filterStartDate", {});
    fp.setDate(new Date(`${year}/${month}/${date}`))
    $("#txtDatePicker").text(`${convertDayStr(day)}, ${left_justify(date.toString())} ${convertMonthStr(month)} ${year}`)
    filterEquipmentBookings()
  }

  window.next_day_filter_desktop = function(call_func) {
    var mode = $("#selectMode").val()
    increase_day(mode, "#txtDatePicker", "#filterStartDate", "#filterEndDate")
    switch(call_func) {
      case "filterMyBookings":
        filterMyBookings()
        break
      default:
        filterEquipmentBookings()
        break
    }
  }

  window.previous_day_filter_desktop = function(call_func) {
    var mode = $("#selectMode").val()
    decrease_day(mode, "#txtDatePicker", "#filterStartDate", "#filterEndDate")
    switch(call_func) {
      case "filterMyBookings":
        filterMyBookings()
        break
      default:
        filterEquipmentBookings()
        break
    }
  }

  window.increase_day = function(mode, id_text, id_start_picker, id_end_picker, type) {
    var str_date = $(id_start_picker).val()
    var date = new Date(str_date)
    var start_fp = flatpickr(id_start_picker, {})
    var end_fp = flatpickr(id_end_picker, {})
    switch(mode) {
      case "daily":
        var increase_start_date = new Date(date.setDate(date.getDate() + 1))
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} ${start_year}`)
        break
      case "weekly":
        var increase_start_date = new Date(date.setDate(date.getDate() + 7))
        var increase_end_date = new Date(date.setDate(date.getDate() + 6))
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        var end_date = increase_end_date.getDate();
        var end_month = increase_end_date.getMonth() + 1;
        var end_year = increase_end_date.getFullYear();
        var end_day = increase_end_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${end_year}/${end_month}/${end_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
      case "monthly":
        var increase_start_date = new Date(date.setMonth(date.getMonth() + 1))
        var last_date = new Date(date.setMonth(date.getMonth()))
        var increase_end_date = new Date(last_date.getFullYear(), last_date.getMonth() + 1, 0);
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        var end_date = increase_end_date.getDate();
        var end_month = increase_end_date.getMonth() + 1;
        var end_year = increase_end_date.getFullYear();
        var end_day = increase_end_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${end_year}/${end_month}/${end_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
    }
  }

  window.decrease_day = function(mode, id_text, id_start_picker, id_end_picker, type) {
    var str_date = $(id_start_picker).val()
    var date = new Date(str_date)
    var start_fp = flatpickr(id_start_picker, {})
    var end_fp = flatpickr(id_end_picker, {})
    switch(mode) {
      case "daily":
        var increase_start_date = new Date(date.setDate(date.getDate() - 1))
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} ${start_year}`)
        break
      case "weekly":
        var increase_start_date = new Date(date.setDate(date.getDate() - 7))
        var increase_end_date = new Date(date.setDate(date.getDate() + 6))
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        var end_date = increase_end_date.getDate();
        var end_month = increase_end_date.getMonth() + 1;
        var end_year = increase_end_date.getFullYear();
        var end_day = increase_end_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${end_year}/${end_month}/${end_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
      case "monthly":
        var increase_start_date = new Date(date.setMonth(date.getMonth() - 1))
        var last_date = new Date(date.setMonth(date.getMonth()))
        var increase_end_date = new Date(last_date.getFullYear(), last_date.getMonth() + 1, 0);
        var start_date = increase_start_date.getDate();
        var start_month = increase_start_date.getMonth() + 1;
        var start_year = increase_start_date.getFullYear();
        var start_day = increase_start_date.getDay();
        var end_date = increase_end_date.getDate();
        var end_month = increase_end_date.getMonth() + 1;
        var end_year = increase_end_date.getFullYear();
        var end_day = increase_end_date.getDay();
        start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
        end_fp.setDate(new Date(`${end_year}/${end_month}/${end_date}`))
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
    }
  }

  window.onChangeCalendar = function(mode, id_text, id_start_picker, id_end_picker, start_year, start_month, start_date, end_year, end_month, end_date, ctrl_page) {
    var start_fp = flatpickr(id_start_picker, {})
    var end_fp = flatpickr(id_end_picker, {})
    start_fp.setDate(new Date(`${start_year}/${start_month}/${start_date}`))
    end_fp.setDate(new Date(`${end_year}/${end_month}/${end_date}`))
    switch(mode) {
      case "daily":
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} ${start_year}`)
        break
      case "weekly":
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
      case "monthly":
        $(id_text).text(`${left_justify(start_date.toString())} ${convertMonthStr(start_month)} - ${left_justify(end_date.toString())} ${convertMonthStr(end_month)} ${end_year}`)
        break
    }
    switch(ctrl_page) {
      case "filterEquipmentBookings":
        filterEquipmentBookings()
        break
      case "filterMyBookings":
        filterMyBookings()
        break
    }
  }

  window.convertDayStr = function(number) {
    switch(number) {
      case 0:
        return "Sun"
      case 1:
        return "Mon"
      case 2:
        return "Tue"
      case 3:
        return "Wed"
      case 4:
        return "Thu"
      case 5:
        return "Fri"
      case 6:
        return "Sat"
    }
  }

  window.convertMonthStr = function(number) {
    switch(number) {
      case 1:
        return "January"
      case 2:
        return "February"
      case 3:
        return "March"
      case 4:
        return "April"
      case 5:
        return "May"
      case 6:
        return "Jun"
      case 7:
        return "July"
      case 8:
        return "August"
      case 9:
        return "September"
      case 10:
        return "October"
      case 11:
        return "November"
      case 12:
        return "December"
    }
  }
});
