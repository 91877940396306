window.addEventListener('DOMContentLoaded', () => {
	window.onChangeCheckbox = function(e, id) {
		var arr_selected_ids = $(id).val().split(",")
		var e_id = $(e).attr('eq_id')
		if ( e.prop('checked') == true ) {
			e.next().removeClass('d-none')
			arr_selected_ids.push(e_id)
			if ( e.prop('checked') == true && $('.check-box-eq:checked').length == $('.check-box-eq').length ) {
	      $('#check_all_data').prop('checked', true)
	    }
 		} else {
 			arr_selected_ids = arr_selected_ids.filter(e => e !== e_id);
			$('#check_all_data').prop('checked', false)
			e.next().addClass('d-none') 
 		}
 		arr_selected_ids = arr_selected_ids.filter(e => e !== "");
 		$(id).val(arr_selected_ids.join())
	}

	window.onChangeAllCheckbox = function(e, id) {
		var arr_selected_ids = $(id).val().split(",")
		$("#group_item_checkbox").find(".check-box-eq").each(function(l) {
			console.log($(l).prop('checked'))
		})
		if ( e.prop('checked') == true ) {
			[...$(".check-box-eq")].forEach(ei => {
        if ($(ei).prop('checked') == false) {
					$(ei).prop('checked', true)
					$(ei).next().removeClass('d-none')
        	var e_id = $(ei).attr('eq_id')
	        arr_selected_ids.push(e_id)
        }
      });
		} else {
			[...$(".check-box-eq")].forEach(el => {
				if ($(el).prop('checked') == true) {
					$(el).prop('checked', false)
					$(el).next().addClass('d-none')
	        var e_id = $(el).attr('eq_id')
	        arr_selected_ids = arr_selected_ids.filter(em => em !== e_id);
				}
      });
		}
		arr_selected_ids = arr_selected_ids.filter(er => er !== "");
 		$(id).val(arr_selected_ids.join())
	}
	
	window.closeSelect2Form = function(item_ids, id, form_id) {
		var selected_ids = $(item_ids).val()
		$(id).val(selected_ids.join())
		$(form_id).modal('hide')
	}

	window.changeSelect2Form = function(item_ids, id, form_id) {
		value     = $(id).val()
    arr_value = value.split(",")
    $(item_ids).val(arr_value)
    $(item_ids).trigger('change')
    $(form_id).modal('hide')
	}
})
