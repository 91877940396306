document.addEventListener("turbolinks:load", function() {
  window.onchange_select_time = function(cthis) {
    var data_type = $(cthis).data("type")
    var $target = $(cthis).closest("#select_time_dialog")
    var data_target = $target.data("target_id")
    var hour = $target.find(".hour-select").val()
    var min = $target.find(".min-select").val()
    var ampm = $target.find(".ampm-select").val()
    $(data_target).val(`${hour}:${min} ${ampm}`)
  }
})
