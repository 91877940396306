window.addEventListener('DOMContentLoaded', () => {
	window.disableDescriptionPage = function() {
		$("#description_page").addClass("pe-none opacity-05")
    $("#group_description_page").find(".loader-place").removeClass("d-none")
	}

	window.enableDescriptionPage = function() {
		$("#group_description_page").find(".loader-place").addClass("d-none")
		$("#description_page").removeClass("pe-none opacity-05")
	}

	window.disableCustomPage = function(div_id, loader_id) {
		$(div_id).addClass("pe-none opacity-05")
    $(loader_id).find(".loader-dynamic").removeClass("d-none")
	}

	window.enableCustomPage = function(div_id, loader_id) {
		$(loader_id).find(".loader-dynamic").addClass("d-none")
		$(div_id).removeClass("pe-none opacity-05")
	}
});